<template>
  <div class="merchants">
    <div v-if="list && list.length > 0" class="list">
      <div v-for="item in list" :key="item.id" class="item">
        <div class="header">
          <div class="name">
            <div class="acct-name" v-text="item.acctName"></div>
          </div>
        </div>
        <div class="body">
          <div v-if="item.companyName" class="info">
            <label>商户类型: </label>
            <div v-text="getMerType(item.merType)"></div>
          </div>
          <div v-if="item.merAddress" class="info">
            <label>商户地址: </label>
            <div v-text="item.merAddress"></div>
          </div>
          <div v-if="item.merContactName" class="info">
            <label>联系人: </label>
            <div v-text="item.merContactName"></div>
          </div>
          <div v-if="item.merContactPhone" class="info">
            <label>联系电话: </label>
            <div v-text="item.merContactPhone"></div>
          </div>
          <div v-if="item.merContactPhone" class="info">
            <label>申请时间: </label>
            <div v-text="item.merContactPhone"></div>
          </div>
        </div>
        <div class="footer">
          <van-button class="check" round plain hairline type="warning" size="mini" @click="showTradeList(item.id)">历史交易
          </van-button>

          <van-button class="check" round plain hairline type="info" size="mini" @click="showMerchants(item.id)">商户详情
          </van-button>
        </div>
      </div>
    </div>
    <template v-else>
      <van-empty v-if="!isLoading" description="暂无商户信息"/>
    </template>
  </div>
</template>

<script>
import Vue from 'vue';
import {Toast, Tag} from 'vant';

Vue.use(Tag);
export default {
  name: "my_merchants",
  data() {
    return {
      isLoading: true,
      list: []
    }
  },
  computed: {
    getMerType() {
      return (value) => {
        let type = Number.parseInt(value.toString())
        return type === 1 ? '个人' : type === 2 ? '企业' : '个体工商户'
      }
    },
  },
  mounted() {
    Toast.loading({
      message: '加载中...',
      forbidClick: true,
    });
    Http.post('frontend/merchant/myMerchantList').then(result => {
      // console.log(result)
      if (result && result.code === 200 && result.data && result.data.content && result.data.content.length > 0) {
        this.list = result.data.content
      }
    }).catch(error => {
      // console.log(error)
    }).finally(() => {
      this.isLoading = false
      Toast.clear()
    })
  },
  methods: {
    showTradeList(id){
      this.$router.push({
        path: '/h5/subtradelist',
        query: {merchantId: id}
      })
    },

    showMerchants(id) {
      this.$router.push({
        path: '/h5/mine/merchant/info',
        query: {id: id}
      })
    }
  }
}
</script>

<style scoped lang="scss">
.merchants {
  .list {
    height: auto;
    overflow: hidden;
    padding: 1rem;

    .item {
      border: 1px solid #eee;
      border-radius: 6px;
      margin-bottom: 1rem;

      .header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: .5rem;
        border-bottom: 1px solid #eee;
        font-weight: bolder;
        font-size: 1.1rem;
        background: #eee;

        .icon {
          display: block;
          margin-right: .5rem;
          flex: 0 0 auto;
        }

        .name {
          flex: 1 0 auto;
          width: 0;

          .acct-name {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }
      }

      .body {
        padding: .5rem;
        color: #666;

        .info {
          font-size: .9rem;
          line-height: 2;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;

          label {
            padding-right: 10px;
          }
        }
      }

      .footer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border-top: 1px solid #eee;
        padding: 10px;

        .check {
          display: block;
          padding: 5px 20px;
          margin: 0 10px;
          height: 30px;
        }
      }
    }
  }
}

</style>